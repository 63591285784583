
import '../App.css';
import config from '../config.js';

// import headshot from "../assetts/IMG_3872.jpg";
import albumCover from "../assetts/a1239594958_10.jpg";
import jacksonFive from "../assetts/jackson_five.mp4";

import { 
    Container, 
    Ratio,
    Stack,
} from 'react-bootstrap';
  
import {
    Link
} from "react-router-dom";

// TODO
// change bio
let About = () => {
    return (
        <Container>
            <Stack gap={3}>
            
                <div className="appHeader">
                    <h1>{config['title']}</h1>
                </div>

                <div className="bioContainer">
                    <p className="wrappedBio">
                        {config['videoIntro']}
                    </p>
                </div>

                <Ratio aspectRatio="16x9">
                    <video 
                    id="jacksonFive" 
                    controls>

                    <source 
                        src={jacksonFive} 
                        type="video/mp4" />

                    </video>
                </Ratio>

                <div className="bioContainer">
                    <p className="wrappedBio">
                        {config['firstDisclaimer']}
                    </p>
                </div>

                {/* <div className="bioContainer">
                    <p className="wrappedBio">
                        {config['ptOne']}
                    </p>
                </div>

                <div className="bioContainer">
                    <p className="wrappedBio">
                        {config['ptOneFirstParagraph']}
                    </p>
                </div>

                <div className="bioContainer">
                    <p className="wrappedBio">
                        {config['ptOneSecondParagraph']}
                    </p>
                </div>

                <div className="bioContainer">
                    <p className="wrappedBio">
                        {config['ptOneThirdParagraph']}
                    </p>
                </div>

                <div className="bioContainer">
                    <p className="wrappedBio">
                        {config['ptOneFourthParagraph']}
                    </p>
                </div>

                <div className="bioContainer">
                    <p className="wrappedBio">
                        {config['ptOneFifthParagraph']}
                    </p>
                </div>

                <div className="bioContainer">
                    <p className="wrappedBio">
                        {config['ptOneSixthParagraph']}
                    </p>
                </div>

                <div className="bioContainer">
                    <p className="wrappedBio">
                        {config['ptOneSeventhParagraph']}
                    </p>
                </div>

                <div className="bioContainer">
                    <p className="wrappedBio">
                        {config['ptTwo']}
                    </p>
                </div> */}

                <div className="bioContainer">
                    <p className="wrappedBio">
                        {config['ptTwoFirstParagraph']}
                    </p>
                </div>

                <div className="bioContainer">
                    <p className="wrappedBio">
                        {config['ptTwoSecondParagraph']}
                    </p>
                </div>

                <div className="bioContainer">
                    <p className="wrappedBio">
                        {config['ptTwoThirdParagraph']}
                    </p>
                </div>

                <div className="bioContainer">
                    <p className="wrappedBio">
                        {config['ptTwoFourthParagraph']}
                    </p>
                </div>

                <div className="bioContainer">
                    <p className="wrappedBio">
                        {config['ptTwoFifthParagraph']}
                    </p>
                </div>

                <div className="bioContainer">
                    <p className="wrappedBio">
                        {config['ptTwoSixthParagraph']}
                    </p>
                </div>
{/* 
                <div className="bioContainer">
                    <p className="wrappedBio">
                        {config['ptTwoSeventhParagraph']}
                    </p>
                </div>

                <div className="bioContainer">
                    <p className="wrappedBio">
                        {config['ptTwoEightParagraph']}
                    </p>
                </div>

                <div className="bioContainer">
                    <p className="wrappedBio">
                        {config['ptTwoNinthParagraph']}
                    </p>
                </div>

                <div className="bioContainer">
                    <p className="wrappedBio">
                        {config['ptTwoTenthParagraph']}
                    </p>
                </div>

                <div className="bioContainer">
                    <p className="wrappedBio">
                        {config['ptTwoEleventhParagraph']}
                    </p>
                </div>

                <div className="bioContainer">
                    <p className="wrappedBio">
                        {config['ptTwoTwelfthParagraph']}
                    </p>
                </div> */}

                <Ratio>
                    <img 
                        className="albumCover" 
                        src={albumCover} />
                </Ratio>

                <div className="bioContainer">
                    <pre className="wrappedBio">
                        {config['ptThreeTrackOne']}
                    </pre>
                </div>

                <div className="bioContainer">
                    <pre className="wrappedBio">
                        {config['ptThreeTrackTwo']}
                    </pre>
                </div>

                <div className="bioContainer">
                    <pre className="wrappedBio">
                        {config['ptThreeTrackThree']}
                    </pre>
                </div>

                <div className="bioContainer">
                    <pre className="wrappedBio">
                        {config['ptThreeTrackFour']}
                    </pre>
                </div>

                <div className="bioContainer">
                    <pre className="wrappedBio">
                        {config['ptThreeTrackFive']}
                    </pre>
                </div>

                <div className="bioContainer">
                    <p className="wrappedBio">
                        <Link to="/">Listen to Babe</Link>
                    </p>
                </div>

                <div className="bioContainer">
                    <p className="wrappedBio">
                        <a href="https://www.linkedin.com/mwlite/in/mike-good-474601126">Michael Gooods Linkedin</a>
                    </p>
                </div>

                

            </Stack>
        </Container>
    );
}

export { About };