let config = { 
    title: 'Viva Babe Curry',
    videoIntro: "Here is a video about the project I worked on with professor Rowland. As you can see, he is not the only MTSU graduate involved. Additionally, Professor Rowland also got me to demo the AudioShake software during the previous semester (a stem separation software). This is another powerful plug-in to use alongside Dolby Atmos for restoration purposes.",
    firstDisclaimer: "(TL;DR) DISCLAIMER: This is a nearly 2000-word description of this project of mine. It is not required to read it to form an opinion of my work. The first 864 words are not included in my letter of intent. The rest are.",
    ptOne: "Pt. 1:",
    ptOneFirstParagraph: "To paraphrase one of my favorite songwriters and producers, Scott McMicken of the band Dr. Dog, a slight variation in tempo and a slight imperfection in pitch create for more depth in a song. This is a tough balance to strike, but I have been pushing for it for over a decade. My name is Michael Good (stage name Babe Curry), and I have been trying to recreate these beautiful imperfections of vintage recording in Pro Tools, Logic, and Abelton for over a decade. In addition, I have gradually blended them with more modern and digital techniques. With my portfolio, this is exactly what I aimed to do with each of these projects.",
    ptOneSecondParagraph: "My general experience and interests are discussed more on my letter of intent (which is also on the following page), but they range from traditional folk/country/blues music in an authentic residency for several years to using Izotope RX to remove clicks with spectral repair per Professor Rowland's request for a restoration of Jackson 5's first single “Big Boy.” Please let me know if you want to see any of these files. I fixed nine markers of clicks by headphone. He wanted me to use headphones because he is planning on creating a Dolby Atmos mix with the assistance of a stem separator program he helped me demo called AudioShake. I hope to get experience with this expensive software during my time at MTSU.",
    ptOneThirdParagraph: "This project begins with a video introduction. It is primarily a compilation of my original songs, except for two (“Kansas City” and “Sleeping on the Blacktop”). The vision can override what we are hearing, as professor Cozy would say. The visuals are slightly delayed from the audio to lessen this effect. They are some clips my performances over the past decade in a rock and authentic folk/country/blues setting. I edited them using VideoLeap Pro.",
    ptOneFourthParagraph: "The first full composition I am showcasing one of my favorite songs I've written, “Eternal Sunshine.” This track featuring  Emily Clement on female vocals  and Dick Aven on sax and flutes. Dick Aven is a career musician who toured with the Temptations and has been a great mentor and teacher for me over the past few years in regards to rhythm. He specializes in odd time signatures such as 5/4, 7/8, and 11/4. I have written full songs in each of these since then. The chorus melody is inspired by the wild melodies of Dave Longstreth from the Dirty Projectors. This song, as well as the next two songs, were mixed on a vintage 32 track Trident console with the assistance of Cameron Davidson, an MTSU RIM graduate. We recorded and mixed these original songs at the historic Cowboy Arms Hotel and Recording Spa, the home studio of the legendary Cowboy Jack Clement (a local staple who has produced everyone from Johnny Cash to U2). The rest of the instruments and vocals are vibrations by me.",
    ptOneFifthParagraph: "This second track is my newest single I wrote this past Christmas, and it is called “A Caroler Passed,” a Grateful-Dead-meets-Beach-Boys feel with minimalist tendencies. I commissioned my favorite drummer, Eric Slick of Dr. Dog, for a drum pass on this song because he has the most feel and regulated looseness of any professional drummer I know. Everything else is performed by me.",
    ptOneSixthParagraph: "The final track ”Mind Over Matter,” is probably the most experimental track on this collection. I have rewritten this song many times over the years, but this is my favorite. The initial tracking at the studio sounded nothing like the final product. The only things that stayed the same were the lead vocals and the bass. I ended up finishing the primary tracking and mixing at my home studio, which greatly aided the creative process. My friend even played a bit of sitar to the background. The guest vocals are provided by Nordista Freeze, a great national touring act that came out of the Lipscomb songwriting program, who is also Lighting 100's Music City Mayhem champion currently. Nordista is an old friend of mine that added to the Beach Boys feel of this track. Everything else is performed by me. Additionally, there was a bonus track/video that I made rapidly in Abelton just to showcase my ability there. I added a stock video montage that I made with VideoLeap Pro.",
    ptOneSeventhParagraph: "In closing, the audio to this portfolio was mastered by Cameron Davidson. During this, I took notes next to him about the process. He did some brick-wall limiting and frequency cuts and boosts mostly, with the goal of preserving the original mixes. Overall, the audio production of this project is highly focused around songwriting, instruments, but not so much on showy effects (although a vintage plate reverb unit was used). It certainly is not a typical commercial project, but I believe it is a professionally creative one. Regardless of where my education goes in the future, I will continue to learn and implement critical information while obtaining my degree at MTSU in pursuit of my goal to become a RIM professor one day and sharing these idiosyncratic techniques, whether that is in the next few years or much farther down the line (as discussed in my letter of intent).",
    ptTwo: "(TL;DR) Pt. 2: (This is just a copy of the letter of intent).",
    ptTwoFirstParagraph: "Mike Good's family moved here in 2007 as his father's wishes to pursue his own musical career. Mike began writing songs and playing all the instruments on the recordings in early high school and sharing CDs with classmates, and forming bands. He always had a governing hand in the recording process. Starting on a digital Zoom 8 track, then to multiple DAWs, and now he has been recording to one inch tape on a 16 track console. He has worked closely with many songwriters and engineers over the years, such as Daniel Rowland (MTSU audio professor), Hayes Helsper (Vanderbilt/Dark Horse grad), Cameron Davidson (MTSU audio grad, has worked with Adrian Belew and Sturgil Simpson). Mike has played his music at numerous venues around town, including at the Franklin Theatre and the Franklin Main Street festival. He has also played with his band at pretty much every dive bar in the city a  handful of times over the years. He has worked in local studios producing and engineering his own music at Dark Horse and The Cowboy Arms Hotel and Recording Spa as well. Mike also edits music for his sister who is an internationally acclaimed circus act, who has preformed 33 NBA halftimes. ",
    ptTwoSecondParagraph: "In addition to his music pursuits, he earned a B.S. degree in speech and hearing science. “I wanted to learn about the science of sound as humans produce it from the most rudimentary and physical level.” he puts it.  In this, he spent two semesters as a clinician working with populations such as individuals down syndrome and speech impairment. Following his degree, he worked as a special education teacher’s assistant at an elementary school for a year, in addition to volunteering for inclusive dance programs for special needs individuals.",
    ptTwoThirdParagraph: "The plan was to always attend grad school, but Covid put things into a bit of a spin, and Mike started working at a paint store called Hoover paint in November 2022. This was a continuation of the contracting and paint work he has done under his father’s business since he was fifteen years old. He says, “Something interesting about working  paint and colors is that it can be very similar to the artistic process of mixing a song. To match a color, for example, it takes a lot of incremental adjustments to get to a final product.” In a way, Mike’s head has always been in the same place whether he is working in music production or another field, he wants to discover the deep truths that technicalities and analytical thinking can contribute to. Giving him the chance to attend this grad school program can only expound upon that.",
    ptTwoFourthParagraph: "Last year, Mike was waitlisted for the MTSU audio grad program, and accepted into the Belmont one. He attended the program for three days before dropping out. This was due to health issues that interfered with his mobility, and ultimately his finances. Now, one year later, he is living with a roommate in the Brentwood area and loving his job at the paint store. He plans to continue this job through college until he lands a job doing something technical in audio engineering. He has a great interest in acoustics and sound design that could contribute a valuable hand in any professional studio or concert hall. ",
    ptTwoFifthParagraph: "The selections of songs used for this portfolio is from Mike’s new album coming out soon. He went to his friend’s studio, Blake Parker (who has engineered for Marty Stuart), and recorded a whole album using nothing but one inch tape to a sixteen track console. In addition, he would also help Blake with the calibration of the machine, learning a lot of useful technical  information that will assist him in working with analog gear.",
    ptTwoSixthParagraph: "\"I hope to get another chance to prove my worth to you all and show you a technical ear and creative mindset that you haven’t yet seen in your program. Thank you.\" -Mike Good, May 2023",
    ptTwoSeventhParagraph: "",
    ptTwoEightParagraph: "",
    ptTwoNinthParagraph: "",
    ptTwoTenthParagraph: "",
    ptTwoEleventhParagraph: "",
    ptTwoTwelfthParagraph: "",
    ptThreeTrackOne: "Video 1:\nTitle: Patience in the Garden\nComposer: All instruments by Mike Good Engineering and production help by Blake Parker",
    ptThreeTrackTwo: "Track 2:\nTitle: From Her Perspective\nComposer: All instruments by Mike Good Engineering and production help by Blake Parker",
    ptThreeTrackThree: "Track 3:\nTitle: Duet 2\nComposer: Mike Good\nPerformers: All instruments by Mike Good Engineering and production help by Blake Parker",
    ptThreeTrackFour: "Track 4:\nTitle: On My Own 2\nComposer: Mike Good\nPerformers: All instruments by Mike Good Engineering and production help by Blake Parker",
    ptThreeTrackFive: "Track 4:\nTitle: Ripping Through The Grocery Parking Lot\nComposer: Mike Good\nPerformers: All instruments by Mike Good Engineering and production help by Blake Parker",
    ptThreeTrackSix: "Video 5:\nTitle: In the Hall of the Mountain Mike \nComposer: All instruments by Mike Good Engineering and production help by Blake Parker",
    videoUri: 'https://player.vimeo.com/video/674512369?h=198dc2a10c',
    firstParagraph: "Patience in the Garden",
    secondParagraph: "From Her Perspective",
    thirdParagraph: "Duet 2",
    fourthParagraph: "On My Own 2",
    fifthParagraph: "Ripping Through The Grocery Parking Lot",
    sixthParagraph: "In the Hall of the Mountain Mike",
    signature: "Thank you, MG M01352999"
};
export default config