import './App.css';
import { tag } from './modules/Tag.js';
import ScrollToTop from './modules/ScrollToTop.js';

import { About } from "./controllers/AboutController";
import { Home } from "./controllers/HomeController";



import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";


tag();
function NavSwitch() {
  return(
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
      </Routes>
    </div>
  );
}

export default function App() {
  return (
      <Router>
          <ScrollToTop />
          <NavSwitch />
      </Router>
  );
}