import React from "react";

import '../App.css';
import config from '../config.js';

import Duet2 from "../assetts/Mike_Masters/Duet2.wav";
import FromHerPerspective from "../assetts/Mike_Masters/FromHerPerspective.wav";
import OnMyOwn2 from "../assetts/Mike_Masters/OnMyOwn2.m4a";
import RippingThroughTheGroceryParkingLot from "../assetts/Mike_Masters/RippingThroughTheGroceryParkingLot.wav";


import firstVideo from "../assetts/PatienceintheGarden.mp4";
// import secondVideo from "../assetts/MountainMike.mp4";


import { 
  Container, 
  Ratio,
  Stack,
} from 'react-bootstrap';

import {
  Link
} from "react-router-dom";


class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
    };

    this.firstVideoRef = React.createRef();
    // this.secondVideoRef = React.createRef();
    
    this.firstTrackRef = React.createRef();
    this.secondTrackRef = React.createRef();
    this.thirdTrackRef = React.createRef();
    this.fourthTrackRef = React.createRef();
  }
  
  componentDidMount() {
    this.mediaAutoPlayQueue();
  }
  
  mediaAutoPlayQueue() {
    // contentDocument
    // let firstVideo = document.getElementById('firstVideo');
    let firstVideo = this.firstVideoRef.current;
    // let secondVideo = this.secondVideoRef.current;
    
    let firstTrack = this.firstTrackRef.current;
    let secondTrack = this.secondTrackRef.current;
    let thirdTrack = this.thirdTrackRef.current;
    let fourthTrack = this.fourthTrackRef.current;

    firstVideo.onplay = () => {
      // secondVideo.pause();
      firstTrack.pause();
      secondTrack.pause();
      thirdTrack.pause();
      fourthTrack.pause();
    }

    // secondVideo.onplay = () => {
    //   firstVideo.pause();
    //   firstTrack.pause();
    //   secondTrack.pause();
    //   thirdTrack.pause();
    //   fourthTrack.pause();
    // }

    firstTrack.onplay = () => {
      firstVideo.pause();
      // secondVideo.pause();
      secondTrack.pause();
      thirdTrack.pause();
      fourthTrack.pause();
    }

    secondTrack.onplay = () => {
      firstVideo.pause();
      // secondVideo.pause();
      firstTrack.pause();
      thirdTrack.pause();
      fourthTrack.pause();
    }

    thirdTrack.onplay = () => {
      firstVideo.pause();
      // secondVideo.pause();
      firstTrack.pause();
      secondTrack.pause();
      fourthTrack.pause();
    }

    fourthTrack.onplay = () => {
      firstVideo.pause();
      // secondVideo.pause();
      firstTrack.pause();
      secondTrack.pause();
      thirdTrack.pause();
    }

    firstTrack.onended = () => {
      secondTrack.play();
    }

    secondTrack.onended = () => {
      thirdTrack.play();
    }

    thirdTrack.onended = () => {
      fourthTrack.play();
    }

    fourthTrack.onended = () => {
      // secondVideo.play();
    }


    firstVideo.onended = () => {
      firstTrack.play();
    }

    // firstTrack.onended = () => {
    //   secondTrack.play();
    // }

    // secondTrack.onended = () => {
    //   thirdTrack.play();
    // }

    // thirdTrack.onended = () => {
    //   // secondVideo.play();
    // }

    // TODO:
    // create logic to stop other tracks from playing simulatnously

  }
  
    
  render() {    
    return (
      <Container>
        <Stack gap={3}>
              
          <div className="appHeader">
            <h1>{config['title']}</h1>
          </div>
  
          <div className="bioContainer">
            <p className="wrappedBio">
              {config['firstParagraph']}
            </p>
          </div>
              
          <Ratio aspectRatio="4x3">
            <video 
              id="firstVideo" 
              controls
              ref={this.firstVideoRef}>

              <source 
                src={firstVideo} 
                type="video/mp4" />
            </video>
          </Ratio>
  
          <div className="bioContainer">
            <p className="wrappedBio">
              {config['secondParagraph']}
            </p>
          </div>

          <div className="audioPlayer">
            <audio 
              id="firstTrack" 
              controls
              ref={this.firstTrackRef}>

              <source 
                src={FromHerPerspective} 
                type="audio/wav" />

            </audio>
          </div>
  

          <div className="bioContainer">
            <p className="wrappedBio">
              {config['thirdParagraph']}
            </p>
          </div>  
  
          <div className="audioPlayer">
            <audio 
              id="secondTrack" 
              controls
              ref={this.secondTrackRef}>

              <source 
                src={Duet2} 
                type="audio/wav" />
            </audio>

          </div>
  
          <div className="bioContainer">
            <p className="wrappedBio">
              {config['fourthParagraph']}
            </p>
          </div>

          <div className="audioPlayer">
            <audio 
              id="thirdTrack"
              controls
              ref={this.thirdTrackRef}>

              <source 
                src={OnMyOwn2} 
                type="audio/wav" />

            </audio>
          </div>

          <div className="bioContainer">
            <p className="wrappedBio">
              {config['fifthParagraph']}
            </p>
          </div>

          <div className="audioPlayer">
            <audio 
              id="fourthTrack"
              controls
              ref={this.fourthTrackRef}>

              <source 
                src={RippingThroughTheGroceryParkingLot} 
                type="audio/wav" />

            </audio>
          </div>
  
          {/* <div className="bioContainer">
            <p className="wrappedBio">
              {config['sixthParagraph']}
            </p>
          </div> */}

          {/* <Ratio aspectRatio="16x9">
            <video 
              // id="secondVideo" 
              controls
              // ref={this.secondVideoRef}>

              <source 
                // src={secondVideo} 
                type="video/mp4" />

            </video>
          </Ratio>
             */}

          <div className="bioContainer">
            <p className="wrappedBio">
              <Link to="/about">View next page for essay, credits, and hollywood project with Professor Rowland:</Link>
            </p>
          </div>
  
        {/* LINKEDIN LINK Here */}
        </Stack>
      </Container>
    );
  }
};
export { Home };