let tag = () => {
    console.log('\n\n\n\n\n\n');
    console.log('\t\t(☭ ͜ʖ ☭) (☭ ͜ʖ ☭) (☭ ͜ʖ ☭) (☭ ͜ʖ ☭) (☭ ͜ʖ ☭) (☭ ͜ʖ ☭)');
    console.log("\t\t(☭ ͜ʖ ☭) (☭ ͜ʖ ☭)  y u peekin???  (☭ ͜ʖ ☭) (☭ ͜ʖ ☭)");
    // console.log("\t\t(☭ ͜ʖ ☭) (☭ ͜ʖ ☭) glenvollmer.com (☭ ͜ʖ ☭) (☭ ͜ʖ ☭)");
    console.log('\t\t(☭ ͜ʖ ☭) (☭ ͜ʖ ☭) (☭ ͜ʖ ☭) (☭ ͜ʖ ☭) (☭ ͜ʖ ☭) (☭ ͜ʖ ☭)');
    console.log('\n');
    console.log('\t\t\t    https://glenvollmer.com');
    console.log('\n\n\n\n\n\n');
};

export { tag };